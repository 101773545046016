@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.about-me {
  background-color: #0f0e17;
  color: white;
  display: flex;
  justify-content: space-around;
  padding: 8% 10%;
}

.fab {
  font-size: 32px;
}

.fab:hover {
  cursor: pointer;
}

.technologies {
  color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.title-about {
  font-weight: 700;
  font-size: 3rem;
}

.tech-div {
  padding: 0 70px;
}

.about-right {
  width: 40%;
}

.margin-icon {
  margin-bottom: 10px;
}

.fa-git:hover{
  color: #E94E31;
}

.fa-python:hover {
  color: #2978A9;
}

.fa-js-square:hover {
  color: #EFD81D;
}

.fa-react:hover {
  color: #5ED3F3;
}

.social-div {
  display: flex;
  justify-content: space-between;
  width: 30%;
}

.social-media {
  color: white;
}

.fa-instagram:hover {
  background: -webkit-linear-gradient(#8C39B0, #F7D177);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fa-linkedin:hover {
  color: #0074B4;
}

.fa-github:hover {
  color: grey;
}

@media screen and (max-width: 1024px) {
  .social-div {
    justify-content: space-around;
    width: 100%;
  }
  .social-media {
    font-size: 48px;
  }
}

@media screen and (max-width: 768px) {
  .title-about {
    font-size: 2.5rem;
  }
  .about-me {
    flex-direction: column;
  }
  .tech-div {
    padding: 0;
    width: 100%;
  }
  hr {
    margin: 50px auto 0;
    width: 100%;
  }
  .about-right {
    width: 100%;
  }
  .social-div {
    justify-content: space-around;
    width: 100%;
  }
  .social-media {
    font-size: 48px;
  }
}

@media screen and (max-width: 320px) {
  .title-about {
    font-size: 2rem;
  }
  hr {
    margin: 30px auto 0;
    width: 100%;
  }
}

.field-area {
  align-self: center;
  box-shadow: rgba(0, 0, 0, 0.9) 0px 5px 15px;
  background-color: #F3F3FC;
  width: 40%;
}

.form-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.div-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80vh;
  padding-bottom: 50px;
}

.input-field {
  border: 1px solid orange;
  border-radius: 7px;
  margin: 8px;
  padding: 10px;
}

textarea {
  border: 1px solid orange;
  margin: 8px;
  height: 150px;
  resize: none;
}

.btn-submit {
  background-color: #ff8906;
  border: 1px solid #cf6f02;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: bolder;
  padding: 15px 20px;
}

.btn-submit:hover {
  border: 1px solid #ff8906;
  cursor: pointer;
  background-color: #cf6f02;
}

.msg-response {
  align-self: flex-end;
  font-weight: bold;
  margin-right: 8px;
}

@media screen and (max-width: 1024px) {
  .div-contact {
    height: auto;
  }
  .field-area {
    width: 75%;
  }
}

@media screen and (max-width: 768px) {
  .field-area {
    width: 80%;
  }
}
.footer-div {
  background-color: #0F0E17;
  color: white;
  padding: 2% 10%;
}

.header-main {
  align-items: center;
  background-color: #0f0e17;
  display: flex;
  min-height: 90vh;
  justify-content: space-between;
  padding-left: 9.9%;
  padding-right: 9.9%;
}

.btn-main {
  background-color: #ff8906;
  border: 1px solid black;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: bolder;
  margin-right: 7px;
  padding: 15px 20px;
  text-decoration: none;
}

.btn-main:hover {
  cursor: pointer;
  background-color: #cf6f02;
}

.header-text {
  width: 50%;
}

.hey-text {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 48px;
  font-weight: 700;
  margin-top: 0;
}

.header-description {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 42px;
}

.header-img-div {
  width: 50%;
}

.header-img {
  width: 500px;
}

@media screen and (max-width: 1024px) {
  .header-img {
    width: 100%;
  }
}

@media screen and (max-width: 960px) {
  .header-main {
    flex-direction: column;
    justify-content: space-evenly;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .header-img {
    width: 65%;
  }
  .header-text {
    width: 100%;
  }
  .header-img-div {
    margin-top: 5%;
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .header-img {
    width: 100%;
    margin: 20px auto;
  }
}

@media screen and (max-width: 414px) {
  .header-img {
    width: 100%;
    margin: 20px auto;
  }
}

@media screen and (max-width: 411px) {
  .header-img {
    width: 100%;
    margin: 20px auto;
  }
}

@media screen and (max-width: 375px) {
  .header-img {
    width: 100%;
    margin: 15px auto;
  }
}

@media screen and (max-width: 360px) {
  .header-img {
    margin: 20px auto;
  }
}

@media screen and (max-width: 320px) {
  .btn-main {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 320px) {
  .div-btns {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
  .btn-main {
    margin: 7px 0 0;
  }
  .header-description {
    margin-bottom: 0;
  }
}
html {
  scroll-behavior: smooth;
}

.main-nav {
  background-color: #0f0e17;
  display: flex;
  font-weight: bolder;
  justify-content: space-between;
  padding: 2.2% 9.9%;
}

.main-nav a {
  align-self: center;
  color: white;
  text-decoration: none;
}

/* New */

nav {
  background-color: #0F0E17;
  display: flex;
  justify-content: space-between;
}

.ul-config {
  display: flex;
  justify-content: space-between;
  width: 25%;
}

nav ul li {
  list-style-type: none;
}

nav ul li a {
  border-radius: 3px;
  color: white;
  opacity: 1.0;
}

@media (max-width: 768px) {
  .ul-config {
    width: 35%;
  }
}

@media (max-width: 540px) {
  .ul-config {
    width: 50%;
  }
}

@media (max-width: 414px) {
  .ul-config {
    width: 0;
  }
  .logo-link {
    width: 100%;
  }
  .remove-nav-window {
    display: none;
  }
}

/* @media (max-width: 280px) {
  .logo-link {
    width: 100px;
  }
} */
.card {
  background-color: rgb(243, 243, 252);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 10px auto;
  padding: 5px 5px;
  width: 30%;
  height: 430px;
}

.padd-element {
  padding-left: 15px;
  padding-right: 15px;
}

.project-image {
  align-self: center;
  width: 300px;
}

.title {
  color: #0f0e17;
}

.description {
  color: #0f0e17;
}

.a-card {
  align-items: flex-end;
  color: #0f0e17;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  text-decoration: none;
}

.a-card:hover {
  color: #cf6f02;
}

@media screen and (max-width: 1024px) {
  .card {
    width: 45%;
  }
}

@media screen and (max-width: 450px) {
  .card {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .project-image {
    width: 100%;
  }
}

.view-link {
  display: flex;
  justify-content: space-between;
  margin-left: 12px;
  margin-right: 12px;
}
.project-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 9.9%;
  padding-right: 9.9%;
}

.project-text {
  color: #0f0e17;
  font-size: 3rem;
  text-align: center;
}

.title {
  font-size: 1.5em;
}

.projects-section {
  padding-bottom: 32px;
}
