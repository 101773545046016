.card {
  background-color: rgb(243, 243, 252);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 10px auto;
  padding: 5px 5px;
  width: 30%;
  height: 430px;
}

.padd-element {
  padding-left: 15px;
  padding-right: 15px;
}

.project-image {
  align-self: center;
  width: 300px;
}

.title {
  color: #0f0e17;
}

.description {
  color: #0f0e17;
}

.a-card {
  align-items: flex-end;
  color: #0f0e17;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  text-decoration: none;
}

.a-card:hover {
  color: #cf6f02;
}

@media screen and (max-width: 1024px) {
  .card {
    width: 45%;
  }
}

@media screen and (max-width: 450px) {
  .card {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .project-image {
    width: 100%;
  }
}

.view-link {
  display: flex;
  justify-content: space-between;
  margin-left: 12px;
  margin-right: 12px;
}