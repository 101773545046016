html {
  scroll-behavior: smooth;
}

.main-nav {
  background-color: #0f0e17;
  display: flex;
  font-weight: bolder;
  justify-content: space-between;
  padding: 2.2% 9.9%;
}

.main-nav a {
  align-self: center;
  color: white;
  text-decoration: none;
}

/* New */

nav {
  background-color: #0F0E17;
  display: flex;
  justify-content: space-between;
}

.ul-config {
  display: flex;
  justify-content: space-between;
  width: 25%;
}

nav ul li {
  list-style-type: none;
}

nav ul li a {
  border-radius: 3px;
  color: white;
  opacity: 1.0;
}

@media (max-width: 768px) {
  .ul-config {
    width: 35%;
  }
}

@media (max-width: 540px) {
  .ul-config {
    width: 50%;
  }
}

@media (max-width: 414px) {
  .ul-config {
    width: 0;
  }
  .logo-link {
    width: 100%;
  }
  .remove-nav-window {
    display: none;
  }
}

/* @media (max-width: 280px) {
  .logo-link {
    width: 100px;
  }
} */