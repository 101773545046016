@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap');

.header-main {
  align-items: center;
  background-color: #0f0e17;
  display: flex;
  min-height: 90vh;
  justify-content: space-between;
  padding-left: 9.9%;
  padding-right: 9.9%;
}

.btn-main {
  background-color: #ff8906;
  border: 1px solid black;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: bolder;
  margin-right: 7px;
  padding: 15px 20px;
  text-decoration: none;
}

.btn-main:hover {
  cursor: pointer;
  background-color: #cf6f02;
}

.header-text {
  width: 50%;
}

.hey-text {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 48px;
  font-weight: 700;
  margin-top: 0;
}

.header-description {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 42px;
}

.header-img-div {
  width: 50%;
}

.header-img {
  width: 500px;
}

@media screen and (max-width: 1024px) {
  .header-img {
    width: 100%;
  }
}

@media screen and (max-width: 960px) {
  .header-main {
    flex-direction: column;
    justify-content: space-evenly;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .header-img {
    width: 65%;
  }
  .header-text {
    width: 100%;
  }
  .header-img-div {
    margin-top: 5%;
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .header-img {
    width: 100%;
    margin: 20px auto;
  }
}

@media screen and (max-width: 414px) {
  .header-img {
    width: 100%;
    margin: 20px auto;
  }
}

@media screen and (max-width: 411px) {
  .header-img {
    width: 100%;
    margin: 20px auto;
  }
}

@media screen and (max-width: 375px) {
  .header-img {
    width: 100%;
    margin: 15px auto;
  }
}

@media screen and (max-width: 360px) {
  .header-img {
    margin: 20px auto;
  }
}

@media screen and (max-width: 320px) {
  .btn-main {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 320px) {
  .div-btns {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
  .btn-main {
    margin: 7px 0 0;
  }
  .header-description {
    margin-bottom: 0;
  }
}