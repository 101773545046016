.project-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 9.9%;
  padding-right: 9.9%;
}

.project-text {
  color: #0f0e17;
  font-size: 3rem;
  text-align: center;
}

.title {
  font-size: 1.5em;
}

.projects-section {
  padding-bottom: 32px;
}