.field-area {
  align-self: center;
  box-shadow: rgba(0, 0, 0, 0.9) 0px 5px 15px;
  background-color: #F3F3FC;
  width: 40%;
}

.form-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.div-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80vh;
  padding-bottom: 50px;
}

.input-field {
  border: 1px solid orange;
  border-radius: 7px;
  margin: 8px;
  padding: 10px;
}

textarea {
  border: 1px solid orange;
  margin: 8px;
  height: 150px;
  resize: none;
}

.btn-submit {
  background-color: #ff8906;
  border: 1px solid #cf6f02;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: bolder;
  padding: 15px 20px;
}

.btn-submit:hover {
  border: 1px solid #ff8906;
  cursor: pointer;
  background-color: #cf6f02;
}

.msg-response {
  align-self: flex-end;
  font-weight: bold;
  margin-right: 8px;
}

@media screen and (max-width: 1024px) {
  .div-contact {
    height: auto;
  }
  .field-area {
    width: 75%;
  }
}

@media screen and (max-width: 768px) {
  .field-area {
    width: 80%;
  }
}